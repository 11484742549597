<template>
  <section class="scrisoare-medicala template flex columns">
    <header>
      <figure class="flex flex-between">
        <!--          <img src="../assets/antet-img.jpg" alt="antet">-->
        <img src="../assets/logo.png" alt="logo" />
      </figure>
    </header>

    <main>
      <h1 class="text-center">SCRISOARE MEDICALĂ</h1>

      <article>
        <ul class="date-pacient">
          <li>
            <b>nume:</b>
            <span class="capitalize"
              >&nbsp;&nbsp;{{ pacient.nume }} {{ pacient.prenume }}</span
            >
          </li>
          <li><b>tel.:</b> &nbsp;&nbsp;{{ pacient.telefon }}</li>
          <li>
            <b>vârstă:</b> &nbsp;&nbsp;{{
              pacient.varsta.replace("(vârsta)", "")
            }}
          </li>
        </ul>

        <p>{{ textScrisoare }} <br /></p>
      </article>
    </main>

    <footer class="flex flex-between">
      <!--      <div>-->
      <!--        <p>-->
      <!--          <span class="capitalize">Dr. {{ user.nume }}</span>-->
      <!--        </p>-->
      <!--      </div>-->

      <!--      <p class="dinstitute-details">-->
      <!--        <b>Premium Dental</b>-->
      <!--        <br>-->
      <!--        adresa: ...-->
      <!--        <br>-->
      <!--        telefon: ...-->
      <!--        <br>-->
      <!--        email: ...-->
      <!--      </p>-->

      <figure>
        <!--        <img src="../assets/footer-fisa-pacient.jpg" alt="footer">-->
      </figure>
    </footer>
  </section>
</template>

<script>
  import { mapState } from 'vuex';
  import moment from 'moment';
  import 'moment/locale/ro';
  moment.locale('ro');

  export default {
    name: 'scrisoare-medicala',
    data () {
      return {
        moment
      };
    },
    computed: {
      ...mapState({
        pacient: 'pacient',
        textScrisoare: 'textScrisoare',
        indexScrisoare: 'indexScrisoare',
        user: (state) => state.user
      })
    }
  };
</script>

<style lang="less">
  @import "../assets/styles/var";
  .scrisoare-medicala {
    h1 {
      //margin-top: 100px;
    }

    article p {
      max-width: 100%;
      word-break: break-word;
      padding: 24px;
      font-size: 1.6rem;
      margin-top: 60px;
      border: 1px solid @medium-grey;
    }

    footer {
      padding: 0 60px;
      margin-top: 24px;
      align-items: flex-start;
      p {
        font-size: 1.6rem;
      }
    }

    .dinstitute-details {
      line-height: 2.5rem;
    }
  }
</style>
